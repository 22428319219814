.public-profile-header {
  
  
  // display: flex;
  flex-direction: row;
  @media (min-width: $lgMaxWidth) {
    // margin:0;´
    background: #F4F4F4 !important;
    padding:0 !important;

  }
}

.public-profile-header__panel-left {
  // min-width: 40%;
  // max-width: 150px;
  
  @media (min-width: $lgMaxWidth) {
    padding:32px !important;
}
}

.public-profile-header__panel-right {
  display: flex;
  justify-content: center !important;
  flex-direction: row;
    gap:64px;
  >div{
    gap:64px;
  }
  @media (max-width: $mdMaxWidth) {
  gap:16px;
  >div{
    gap:16px;
  }
  } 
    @media (min-width: $lgMaxWidth) {
    background:white;
    border-radius: 16px;
    position:relative;
    right:12px;
}
  @media (max-width: $smMaxWidth) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    margin-top:16px !important;
  }
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .trading-info {
          @media (max-width: $smMaxWidth) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .votes-info {
      @media (max-width: $smMaxWidth) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }
    min-width: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: $mdMaxWidth) {
.public-profile-votes-table .ReactTable{
  max-width: 75vw;
  font-size:10px;
}
}
.seller-comments{
  max-width: 600px;
          @media (max-width: $lgMaxWidth) {
            max-width: 400px;
        }
}


.votes-group{
  display: flex;
  flex-direction: row;
}
@media (max-width: $smMaxWidth) { 
  .votes-info{
    align-items:center !important;
  }
  .votes-group{ 
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  .votes-info,.trading-info{
    align-items:center;
    width: 100%;
  }
  div{
    align-items: center;
  }
}
}