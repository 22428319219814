#footer {
    background: $headerBackground;
    width: 100%;
    color: #A4A4A4;
    border-radius: 16px;

    section {
        min-height: inherit;
    }

    a, span, div {
        color: #A4A4A4;
    }
    h1,h2,h3,h4,h5,h6,strong{
        color:white;
    }
    .social-icon {
        margin: 5px;
        width: 18px;
        height: 18px;
    }
    @media (max-width: $smMaxWidth) {
        .alert{
            font-size:10px;
        }
    }

}

#footer-up h3 {
    color: white;
    // font-family: $titleMenuFont;
    font-size: 16.8px;
    font-weight: 400;
    margin: 22px 0 8px;
    text-transform: uppercase;
}

